.contact-section {
  position: relative;
  min-height: 100vh;
  padding: 50px 0;
  background-color: #1f1f1f;
  z-index: 1;
}

.contact-section h1 {
  font-size: 2.5em;
  font-weight: 700;
  color: #c084f5;
  text-align: center;
  margin-bottom: 30px;
}

.contact-section .contact-img {
  text-align: center;
  z-index: 0;
}

.contact-section .img-fluid {
  max-width: 100%;
  height: auto;
  margin-top: 50px;
}

.contact-section form {
  background-color: #2a2a2a;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
  z-index: 1;
  position: relative;
}

.contact-section form label {
  color: #c084f5;
  font-size: 1.1em;
}

.contact-section form input,
.contact-section form textarea {
  background-color: #1f1f1f;
  border: 1px solid #c084f5;
  color: white;
}

.contact-section form input::placeholder,
.contact-section form textarea::placeholder {
  color: #aaaaaa;
}

.contact-section form input:focus,
.contact-section form textarea:focus {
  background-color: #292929;
  border-color: #c084f5;
  color: white;
}

.contact-section form button {
  background-color: #c084f5;
  border: none;
  padding: 10px 20px;
  font-size: 1.1em;
  color: white;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.contact-section form button:hover {
  background-color: #9d69e3;
}
