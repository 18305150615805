.book-section {
  min-height: 100vh;
  padding: 50px 0;
  background-color: #1f1f1f;
  z-index: 1;
}

.book-section h1 {
  font-size: 2.5em;
  font-weight: 700;
  color: #c084f5;
  text-align: center;
  margin-bottom: 20px;
}

.book-section .book-img {
  text-align: center;
  z-index: 0;
}
